<template lang="pug">
  v-container#pricing.fill-height.justify-center(tag="section")
    v-row.text-center(align="center" justify="center")
      v-col.mb-10(cols="12")
        pages-heading.mb-12
          | Pick the best plan for you
        .display-1.font-weight-light.grey--text.text--lighten-2
          | You have Free Unlimited Updates and Premium Support on
          br.hidden-sm-and-down
          | each package.
      template(v-for="(plan, i) in plans")
        v-col.d-flex(:key="i" cols="12" sm="6" md="3")
          pages-plan-card(:plan="plan")
          P JAJAJA
        v-col.pa-6(v-if="(i === 1 && $vuetify.breakpoint.smAndDown) && i + 1 !== plans.length" :key="`divider-${i}`" cols="12")
          v-divider
</template>

<script>
  export default {
    name: 'PagesPricing',

    components: {
      PagesHeading: () => import('./component/Heading'),
      PagesPlanCard: () => import('./component/PlanCard'),
    },

    data: () => ({
      plans: [
        {
          heading: 'Freelancer',
          icon: 'mdi-sofa',
          title: 'Free',
          text: 'This is good if your company size is between 2 and 10 Persons.',
        },
        {
          best: true,
          heading: 'Small Company',
          icon: 'mdi-home',
          title: '29$',
          text: 'This is good if your company size is between 2 and 10 Persons.',
        },
        {
          heading: 'Medium Company',
          icon: 'mdi-domain',
          title: '69$',
          text: 'This is good if your company size is between 11 and 99 Persons.',
        },
        {
          heading: 'Extra Pack',
          icon: 'mdi-bank',
          title: '159$',
          text: 'This is good if your company size is 99+ or greater Persons.',
        },
      ],
    }),
  }
</script>
